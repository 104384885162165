import 'lazysizes'
// eslint-disable-next-line import/extensions
import 'lazysizes/plugins/attrchange/ls.attrchange'
import './critical/experiment-tracking'
import './critical/click-tracking'
import './critical/accordion'
import './css/main-uswitch.css'
import './critical/tab'
import './critical/provider-logo'
import 'lite-youtube-embed/src/lite-yt-embed.css'
import 'lite-youtube-embed/src/lite-yt-embed.js'
import './critical/carousel'
import './critical/superscript'
import './critical/sticky-banner'
import './critical/sticky-nav'
import './critical/tabs-selector'
import './critical/button-atom'
import './critical/platinum-tooltip'
import './critical/sticky-top-nav'
import './critical/top-picks'
import './critical/chart'
import './critical/triage'
import './critical/radio-selector'

if (typeof window.nerdalytics !== 'undefined') {
  window.nerdalytics.page()
}
