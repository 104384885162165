document.querySelectorAll('[data-radio-selector]').forEach((selector) => {
  const radioButtons: NodeListOf<HTMLInputElement> = selector.querySelectorAll(
    '[data-radio-selector-button]',
  )

  const radioSelectorContent: NodeListOf<HTMLElement> = selector.querySelectorAll(
    '[data-radio-selector-content]',
  )

  const clickListener = (ev: MouseEvent) => {
    const target = ev.target as HTMLInputElement
    const index = parseInt(target.dataset.radioSelectorButton ?? '')
    radioButtons.forEach((rb, i) => {
      if (i === index) {
        rb.parentElement?.classList.add('radio-checked')
        rb.checked = true
      } else {
        rb.parentElement?.classList.remove('radio-checked')
        rb.checked = false
      }
    })
    radioSelectorContent.forEach((radioSelector, i) => {
      radioSelector.style.display = i === index ? 'block' : 'none'
    })
  }

  radioButtons.forEach((radioButton: HTMLInputElement) => {
    radioButton.addEventListener('click', clickListener)
  })

  const selectListener = (ev: Event) => {
    const target = ev.target as HTMLSelectElement
    const index = parseInt(target.value ?? '')
    radioSelectorContent.forEach((radioSelector, i) => {
      radioSelector.style.display = i === index ? 'block' : 'none'
    })
  }

  const select: HTMLSelectElement | null = selector.querySelector('select')
  select?.addEventListener('change', selectListener)
})
